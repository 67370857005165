import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/vsts/work/1/s/node_modules/gatsby-theme-docz/src/base/Layout.js";
import "../../src/prism-powershell-language";
import { Navigation } from "../../src/components/Navigation";
import { SelectAllText } from "../../src/components/SelectAllText";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "install-the-tools"
    }}>{`Install the Tools`}</h1>
    <p>{`The quick way to install everything you need, is to copy the below command and run it in an administrative PowerShell window`}<sup parentName="p" {...{
        "id": "fnref-1"
      }}><a parentName="sup" {...{
          "href": "#fn-1",
          "className": "footnote-ref"
        }}>{`1`}</a></sup>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-powershell"
      }}>{`$ErrorActionPreference="Stop";if(-not([Security.Principal.WindowsPrincipal][Security.Principal.WindowsIdentity]::GetCurrent()).IsInRole([Security.Principal.WindowsBuiltInRole]'Administrator')){throw "Run command in an administrator PowerShell prompt"};if($PSVersionTable.PSVersion -lt (New-Object System.Version("3.0"))){throw "The minimum version of Windows PowerShell that is required by the script (3.0) does not match the currently running version of Windows PowerShell."};Set-ExecutionPolicy Bypass -Scope Process -Force;iex ((New-Object System.Net.WebClient).DownloadString('https://vindfang.skillcore.no/public/scripts/install.ps1'))
`}</code></pre>
    <p>{`Let it run for a good minute or two, depending on the speed of your network. ⌛`}</p>
    <h2 {...{
      "id": "git-configuration"
    }}>{`Git Configuration`}</h2>
    <p>{`Next, you'll need to set the contact information which git will use.`}<br parentName="p"></br>{`
`}{`Run the below command and input your email address and full name when prompted:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-powershell"
      }}>{`git config --global user.email (Read-Host -Prompt 'Email Address');git config --global user.name (Read-Host -Prompt 'Full Name')
`}</code></pre>
    <hr />
    <details>
  <summary id="fn-1">
    How to open Windows PowerShell with administrative rights...
  </summary>
  <figure>
    <img src="/public/images/powershell-administrator.png" title="How to open Windows PowerShell with administrative rights" />
    <figcaption>
      Open the start menu, search for Windows PowerShell and select{" "}
      <em>Run as Administrator</em>.
    </figcaption>
  </figure>
    </details>
    <details>
  <summary id="fn-2">Why is PowerShell frozen?</summary>
  <figure>
    <img src="/public/images/powershell-select.png" title="Windows PowerShell freezes when a selection is being made" />
    <figcaption>
      Not many people knows that if you click with your mouse in a PowerShell or
      Terminal window, it starts a selection. This selection blocks the UI
      thread, which in turn blocks the execution of the current script. Simply
      hit <b>ESC</b> on your keyboard to cancel the selection, and the script
      will continue.
      <br />
      <em>
        NOTE: You can easily see if you've made a selection by looking at the
        title bar of the window.
      </em>
    </figcaption>
  </figure>
    </details>
    <br />
    <Navigation previous="/my-first-extension" next="/my-first-extension/create-app" mdxType="Navigation" />
    <SelectAllText selector="pre.prism-code" mdxType="SelectAllText" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      